import styles from './index.module.scss'
import { useScrollVisible } from '@/utils/hooks'
import { scrollToTop } from '@/utils/utils'
import cm from 'classnames'
import { useRouter } from 'next/router'
import { memo } from 'react'

const Index = () => {
  const { pathname } = useRouter()
  const { isVisible } = useScrollVisible()
  const toTop = () => {
    if (isVisible) {
      scrollToTop()
    }
  }

  return (
    <div className={styles.floatBox}>
      {pathname.startsWith('/download') ? null : (
        <div className={cm(styles.floatBoxTv, 'hideWhenPhone')}>
          <div className={styles.floatBoxTvCode}>
            <p className={styles['code-text']}>扫码下载APP，电影电视剧直播台随心看！</p>
            <div className={styles['code-box']}>
              <img loading="lazy" src="/api/images/app_code" alt="二维码" className={styles['code-box-img']} />
            </div>
          </div>
        </div>
      )}
      {isVisible ? <div className={styles.up} onClick={toTop}></div> : null}
    </div>
  )
}

export default memo(Index)
